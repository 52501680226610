<template>
  <div class="loginTabs" id="loginPageTabs">
    <div class="loginTabHeader" @click="openedPanel==1?openedPanel=null:openedPanel=1">
      <div class="mr-4" style="cursor: pointer">
      Аккредитация разработчика
      </div>
      <v-icon color="white" v-if="openedPanel!=1">
        mdi-plus
      </v-icon>
      <v-icon color="white" class="loginTabIcon" v-if="openedPanel==1">
        mdi-minus
      </v-icon>
    </div>
    <v-divider color="white" class="mt-2" />

    <transition name="openTab">
      <div class="loginTabContent" v-if="openedPanel == 1">
        MediaHills с 15 марта 2019 года состоит в реестре аккредитованных Министерством цифрового развития, связи и массовых коммуникаций Российской Федерации организаций, осуществляющих деятельность в области информационных технологий  (с номером записи в реестре 9916). Компания является российским разработчиком ПО «ПРОМЕТЕЙ» и БАЗЫ ДАННЫХ ПО «ПРОМЕТЕЙ».
      </div>
    </transition>

    <div class="loginTabHeader" @click="openedPanel==2?openedPanel=null:openedPanel=2">
      <div class="mr-4" style="cursor: pointer">
        Собственная разработка
      </div>
      <v-icon color="white" class="loginTabIcon" v-if="openedPanel!=2">
        mdi-plus
      </v-icon>
      <v-icon color="white" class="loginTabIcon" v-if="openedPanel==2">
        mdi-minus
      </v-icon>
    </div>
    <v-divider color="white" class="mt-2" />
    <transition name="openTab">
      <div class="loginTabContent" v-if="openedPanel == 2">
        ПО «ПРОМЕТЕЙ» И БАЗЫ ДАННЫХ ПО «ПРОМЕТЕЙ» — собственная информационная система MediaHills по онлайн мониторингу и анализу телесмотрения на основе данных о фактическом просмотре ТВ-каналов, получаемых непосредственно с абонентского оборудования, а также из иных источников.
      </div>
    </transition>

    <div class="loginTabHeader" @click="openedPanel==3?openedPanel=null:openedPanel=3">
      <div class="mr-4" style="cursor: pointer">
        Регистрация ПО
      </div>
      <v-icon color="white" class="loginTabIcon" v-if="openedPanel!=3">
        mdi-plus
      </v-icon>
      <v-icon color="white" class="loginTabIcon" v-if="openedPanel==3">
        mdi-minus
      </v-icon>
    </div>
    <v-divider color="white" class="mt-2" />
    <transition name="openTab">
      <div class="loginTabContent" v-if="openedPanel == 3">
        ПО зарегистрировано на имя Исполнителя в Федеральной службе по интеллектуальной собственности (Свидетельство о регистрации программы для ЭВМ Программное обеспечение «ПРОМЕТЕЙ» № 2022616641 от «15» апреля 2022 г., Свидетельство о регистрации программы для ЭВМ Модуль отчетности ПО «ПРОМЕТЕЙ» № 2022616638 от «15» апреля 2022 г.)
      </div>
    </transition>
  </div>
</template>
<style lang="scss"  scoped>
.theme--light.v-icon:focus::after {
    opacity: 0
}
.openTab-enter-active, .openTab-leave-active {
  transition: all .5s;
  max-height: 230px;
}
.openTab-enter, .openTab-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  max-height: 0px;
}
.loginTabs {
  margin-left: -20px;
  @media screen and (min-width: 944px) {
    padding-right: 10px;
  }
}
.loginTabs::-webkit-scrollbar {
    display: none;
}
.loginTabIcon {
  float: right; cursor:pointer
}
.loginTabHeader {
  display: flex;
  justify-content: space-between;
  color: white;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 23px!important;
  line-height: 32px!important;
  padding-left: 0!important;
  margin-left: 0px!important;
  padding-right: 0!important;
  padding-top: 32px!important;
  @media screen and (min-width: 944px) {
    margin-left: 20px!important;
  }
}
.loginTabContent {
  color: white;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 14px!important;
  line-height: 20px!important;
  letter-spacing: 0.25px!important;
  padding-top: 12px;
  max-width: fit-content;
  padding-left: 0!important;
  margin-left: 0px!important;
  @media screen and (min-width: 944px) {
    margin-left: 20px!important;
  }  
}

.loginTabContent>div {
  padding-left: 0!important;
}
</style>
<script>
export default {
  name: "LoginTabs",
  props: {},
  computed: {},
  methods: {},
  data: () => ({
    openedPanel: null
  }),
};
</script>
